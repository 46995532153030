import React, { Fragment, FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icon from "../Icons/Icon";
import { useJumbotronContext } from "../../contexts/JumbotronContext/JumbotronContext";
import { ColorEnum } from "../../enums/ColorEnum";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "./ReusableJumbotron.module.scss";
import { Props } from "../../shared/intrefaces";

const ReusableJumbotron: FunctionComponent<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { jumbotronProps } = useJumbotronContext();
  const history = useHistory();

  const getStyles = () => {
    const backgroundStyle = styles[`Background-${jumbotronProps.color}`];
    return `${styles.Jumbotron} ${backgroundStyle}`;
  };

  const onBackButtonClick = () => history.goBack();

  const renderBackButton = () => {
    if (!jumbotronProps.hasBackButton) return <Fragment />;

    return (
      <div className={styles.PseudoRelative}>
        <div
          className={styles.BackButton}
          onClick={
            jumbotronProps.onClickCustom
              ? jumbotronProps.onClickCustom
              : onBackButtonClick
          }
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          {t("Back")}
        </div>
      </div>
    );
  };

  const render = () => {
    if (jumbotronProps.icon) {
      return (
        <Fragment>
          <Col xl={1} md={2} className="p-0">
            <div className={styles.Icon}>
              <Icon icon={jumbotronProps.icon} color={ColorEnum.Black} />
            </div>
          </Col>
          <Col xl={11} md={10} sm={12} className="p-0">
            <Row>
              <h1 className={styles.Header}>{jumbotronProps.header}</h1>
            </Row>
            <Row className={styles.Content}>
              <div>
                <p>{jumbotronProps.textLeft}</p>
              </div>
              <div>
                <p className={styles.TextRight}>{jumbotronProps.textRight}</p>
              </div>
            </Row>
          </Col>
        </Fragment>
      );
    }

    return (
      <Col xl={12} className="p-0">
        <Row>
          <h1 className={styles.Header}>{jumbotronProps.header}</h1>
        </Row>
        <Row className={styles.Content}>
          <div>
            <p>{jumbotronProps.textLeft}</p>
          </div>
          <div>
            <p className={styles.TextRight}>{jumbotronProps.textRight}</p>
          </div>
        </Row>
      </Col>
    );
  };

  return (
    <Fragment>
      <div className={getStyles()}>
        <Container className="p-0">
          {renderBackButton()}
          <Row className="p-0">{render()}</Row>
        </Container>
      </div>
      {children}
    </Fragment>
  );
};

export default ReusableJumbotron;
