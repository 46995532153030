import { ColorEnum } from "../../enums/ColorEnum";
import { IconEnum } from "../../features/Icons/IconEnum";

export type JumbotronProps = {
  color: ColorEnum;
  icon?: IconEnum;
  header: string;
  textLeft: string;
  textRight: string;
  hasBackButton?: boolean;
  onClickCustom?: () => void;
};

export const defaultJumbotronProps: JumbotronProps = {
  color: ColorEnum.White,
  header: "",
  textLeft: "",
  textRight: "",
  hasBackButton: true,
};
