export const sizesBox = {
  sm: { span: 12 },
  md: { span: 8, offset: 2 },
  lg: { span: 6, offset: 3 },
};
export const sizesHeader = {
  sm: { span: 12 },
  md: { span: 10, offset: 1 },
  lg: { span: 6, offset: 3 },
};

export const CONTRIBUTION = [
  "YourEmployer",
  "YourEmployerExtraContribution",
  "Yourself",
  "YourExtraContribution",
];
export const CONTRIBUTION_AND_REDEMPTION = [
  "YourEmployer",
  "YourEmployerExtraContribution",
  "YourselfContribution",
  "YourExtraContribution",
  "YourselfRedemption",
  "YourExtraRedemption",
];

export const PROTECTED_ROUTE = ["document"];

export const CERTIFICATES = "Certificates";

export const VALUE = "Value";
export const INCOMING = "Incoming";
export const DOWNLOAD_TRANSACTIONS = "DownloadCSVTransactions";
export const DOWNLOAD_MUTATIONS = "DownloadCSVMutation";
export const DOWNLOAD_EMPLOYEE = "DownloadCSVEmployee";

export const DEVICE_MOBILE_MAX = 768;
