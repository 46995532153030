import { LazyExoticComponent, FunctionComponent } from "react";
import { lazy } from "react";

export interface IRoute {
  path: string;
  component: LazyExoticComponent<FunctionComponent<any>>;
  exact: boolean;
}

const Settings = lazy(() => import("../features/Settings/Settings"));

const AccountOverview = lazy(
  () => import("../features/AccountWidget/Accounts/Accounts")
);

const DocumentsWrapper = lazy(
  () => import("../features/Documents/DocumentsWrapper")
);

const IbanManagementWrapper = lazy(
  () => import("../features/IbanManagement/IbanManagementWrapper")
);

const AddAccountWrapper = lazy(
  () => import("../features/IbanManagement/AddAccount/AddAccountWrapper")
);

const Account = lazy(() => import("../features/AccountOverview/Account"));

const TransactionHistoryWrapper = lazy(
  () => import("../features/TransactionHistory/TransactionHistoryWrapper")
);

const PersonalInfoEditWrapper = lazy(
  () => import("../features/PersonalInfoEdit/PersonalInfoEditWrapper")
);

const Icons = lazy(() => import("../features/Icons/Icons/Icons"));

const Error = lazy(() => import("../features/ErrorPage/ErrorPage"));

const PensionOverview = lazy(
  () => import("../features/AccountWidget/PensionOverview/PensionOverview")
);

const InvestmentMixChange = lazy(
  () => import("../features/InvestmentMixChange/InvestmentMixChange")
);

const UploadXml = lazy(
  () => import("../features/AccountWidget/PensionOverview/UploadXml/UploadXml")
);

export const participantRoutes: IRoute[] = [
  {
    path: "/overzicht/:id",
    component: Account,
    exact: true,
  },
  {
    path: "/documenten",
    component: DocumentsWrapper,
    exact: true,
  },
  {
    path: "/settings",
    component: Settings,
    exact: true,
  },
  {
    path: "/transacties/:id",
    component: TransactionHistoryWrapper,
    exact: true,
  },
  {
    path: "/icons",
    component: Icons,
    exact: true,
  },
  {
    path: "/persoonlijk",
    component: PersonalInfoEditWrapper,
    exact: true,
  },
  {
    path: "/bankgegevens",
    component: IbanManagementWrapper,
    exact: true,
  },
  {
    path: "/rekening-toevoegen",
    component: AddAccountWrapper,
    exact: true,
  },
  {
    path: "/pensioenoverzicht",
    component: PensionOverview,
    exact: true,
  },
  {
    path: "/investmentmixchange/:id",
    component: InvestmentMixChange,
    exact: true,
  },
  {
    path: "/uploadxml",
    component: UploadXml,
    exact: true,
  },
  {
    path: "/error",
    component: Error,
    exact: true,
  },
  {
    path: "/",
    component: AccountOverview,
    exact: false,
  },
];
