import moment from "moment";

const format = "YYYY-MM-DDTHH:mm:ss";
const formatLocal = "DD-MM-YYYY";
const BETABIT_TABLE_DATE_FORMAT = "DD-MM-YYYY HH:mm:ss";

export class DateFormatter {
  public static utcToLocalTime = (date: string): Date => {
    return moment.utc(date, format).local().toDate();
  };

  public static localToUtcTime = (date: Date): string => {
    return moment.utc(date).format(format);
  };

  public static utcToLocal = (date: string, inputFormat = formatLocal) => {
    const isValidDate = moment(date).isValid();
    if (!isValidDate) {
      return moment(date, BETABIT_TABLE_DATE_FORMAT).format(inputFormat);
    }
    return moment.utc(date).local().format(inputFormat);
  };

  public static validDateOrUndefined = (date: string) => {
    return isNaN(Date.parse(date)) ? undefined : new Date(date);
  };

  public static isInThePastOrToday = (date: string): boolean => {
    const today = new Date();
    const dateToCheck = new Date(date);

    today.setHours(0, 0, 0, 0);
    dateToCheck.setHours(0, 0, 0, 0);

    return dateToCheck <= today;
  };

  public static getDateDisplay = (date: string) => {
    const dateFormat = Intl.DateTimeFormat("nl-NL");
    return dateFormat.format(new Date(date));
  };
}
