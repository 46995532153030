import { ReactNode } from "react";
import styles from "./Link.module.scss";
import { ColorEnum } from "../../enums/ColorEnum";

type ExternalLinkProps = {
  href: string;
  children?: ReactNode;
  color?: ColorEnum;
};

export const ExternalLink = ({ children, href, color }: ExternalLinkProps) => {
  let colorClass;
  if (color == ColorEnum.Black) {
    colorClass = styles.Black;
  }

  return (
    <a
      className={`${styles.Link} ${colorClass}`}
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
};
