import { ReactNode, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../../features/Icons/Loader";
import Success from "../../features/Icons/Success";
import ExclamationMark from "../../features/Icons/ExclamationMark";
import styles from "./IconModal.module.scss";
import { useTranslation } from "react-i18next";
import { useButtonAnimation } from "../../hooks/useButtonAnimation";
import { ColorEnum } from "../../enums/ColorEnum";

export enum IconModalTypeEnum {
  Loading = "Loading",
  Success = "Success",
  Error = "Error",
}

interface IconModalOptions {
  type?: IconModalTypeEnum;
  continueButton: boolean;
  continueAction?: () => void;
  closeButton: boolean;
  closeAction?: () => void;
}

type IconModalProps = {
  children?: ReactNode;
  options: IconModalOptions;
};

export const IconModal = ({ children, options }: IconModalProps) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const iconWidth = "5rem";

  const { anim, animationStart, animationEnd, buttonStyle } =
    useButtonAnimation(ColorEnum.Pink);

  const closeModal = () => {
    animationStart();
    setShowPopup(false);

    if (options.closeAction) {
      options.closeAction();
    }
  };

  const continueModal = () => {
    closeModal();

    if (options.continueAction) {
      options.continueAction();
    }
  };

  const renderIcon = () => {
    if (!options.type) {
      return null;
    } else if (options.type == IconModalTypeEnum.Loading) {
      return <Loader width={iconWidth} height={iconWidth}></Loader>;
    } else if (options.type == IconModalTypeEnum.Success) {
      return <Success width={iconWidth} height={iconWidth}></Success>;
    } else if (options.type == IconModalTypeEnum.Error) {
      return (
        <ExclamationMark width={iconWidth} height={iconWidth}></ExclamationMark>
      );
    }
  };

  const renderContinue = () => {
    if (options.continueButton) {
      return (
        <button
          onClick={() => continueModal()}
          onAnimationEnd={() => animationEnd()}
          className={`${buttonStyle} ${styles.IconModalContinue}`}
          anim={anim}
        >
          {t("Deposits.DepositSubmitContinue")}
        </button>
      );
    }
  };

  const renderHeader = () => {
    if (options.closeButton) {
      return (
        <Modal.Header
          closeButton
          className={`border-0 ${styles.IconModalClose}`}
        ></Modal.Header>
      );
    }
  };

  return (
    <Modal
      show={showPopup}
      size="lg"
      centered
      onHide={options.continueButton || options.closeButton ? closeModal : null}
    >
      {renderHeader()}
      <Modal.Body>
        <div className={styles.IconModal}>{renderIcon()}</div>
        <div className={styles.IconModalContent}>{children}</div>
        {renderContinue()}
      </Modal.Body>
    </Modal>
  );
};
